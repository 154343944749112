import AOS from "aos";
import lozad from "lozad";
import { setBackgroundElement, detectCloseElement, buttonToTop, clickScrollToDiv, appendCaptchaASP, indicatorSlide, countUpInit } from "./helper";
import { header } from "./header";
import { Fullpage, swiperInit } from "./swiper";
import { ajax } from "./ajax";
$(document).ready(function () {
	setBackgroundElement();
	header.init();
	swiperInit();
	Fullpage.init();
	ajax.init();
	countUpInit();
	hoverFieldActivity();
	indicatorSlide();
	PopupBooking();
	menuSpy();
	clickTabDetailProject();
	itemVarHeight();
	clickHomeAjaxPost();
});
function clickHomeAjaxPost() {
	// Handle tab clicks
	$(".section-home-news .list-tab-primary a").click(function (e) {
		e.preventDefault();
		$(this).parent().addClass("current").siblings().removeClass("current");
		loadContent($(this).data("id"), 1);
	});

	// Handle pagination clicks
	$(document).on("click", ".section-home-news .flat-pagination a", function (e) {
		e.preventDefault();
		$(this).addClass("current").siblings().removeClass("current");

		const categoryId = $(".section-home-news .list-tab-primary .current a").data("id") || 7; // default to 7 if none selected
		const page = $(this).data("paged");

		loadContent(categoryId, page);
	});

	function loadContent(categoryId, page) {
		const rootDomain = window.location.origin;
		$.ajax({
			url: `${rootDomain}/ajax-blogs`,
			data: {
				category_id: categoryId,
				page: page,
			},
			success: function (response) {
				$(".section-home-news .ajaxResponse").html(response.data);
				$(".section-home-news .pagination-wrapper").html(response.pagination);
				window.observer.observe();
			},
		});
	}
}
/*==================== Function Click Tab Detail Project ====================*/
function clickTabDetailProject() {
	$("[data-tab] a").on("click", function (e) {
		e.preventDefault();
		const tabId = $(this).attr("href").substring(1); // Remove # from href

		// Remove active from all content
		$("[data-content]").removeClass("active");
		// Add active to matching content
		$(`[data-content="${tabId}"]`).addClass("active");

		// Optional: Add active state to clicked tab
		$(this).parent().addClass("active").siblings().removeClass("active");
	});
}
/*==================== MenuSpy ====================*/
function menuSpy() {
	if ($("#menu-spy").length) {
		var elm = document.querySelector("#menu-spy");
		var ms = new MenuSpy(elm, {
			activeClass: "active",
			threshold: 100,
		});
	}
}
/*==================== ItemVarHeight ====================*/
function itemVarHeight() {
	$(".item-var-height").each(function () {
		const height = $(this).height();
		console.log("🚀 ~ height:", height);
		$(this)
			.closest(".wrap-item-var-height")
			.css("--height-item-var-height", height + "px");
	});
}
window.itemVarHeight = itemVarHeight;
function hoverFieldActivity() {
	const bg_img = $(".section-home-field-activity").attr("setBackground");

	// Handle hover on images
	$(".section-home-field-activity .swiper .item-image").on("mouseenter", function () {
		const index = $(this).data("index");
		$(".section-home-field-activity .img-bg").eq(index).addClass("active").siblings().removeClass("active");
		$(".section-home-field-activity").find(`.list-activity .item-activity a[data-index="${index}"]`).closest("li").removeClass("hide").siblings().addClass("hide");
	});

	// Handle hover on activity links
	$(".section-home-field-activity .item-activity a").on("mouseenter", function () {
		const index = $(this).data("index");
		const imageItem = $(`.section-home-field-activity .swiper .item-image[data-index="${index}"]`);
		const bg = imageItem.data("bg");
		const slideIndex = imageItem.closest(".swiper-slide").index();
		imageItem.addClass("active").closest(".swiper-slide").siblings().find(".item-image").removeClass("active");
		window.swiperActivityField.slideTo(slideIndex);

		$(".section-home-field-activity").css("background-image", `url('${bg}')`);
		$(this).closest("li").removeClass("hide").siblings().addClass("hide");
	});

	// Reset on mouseleave
	$(".section-home-field-activity .col-span-7 .swiper-wrapper, .section-home-field-activity .list-activity").on("mouseleave", function () {
		$(".section-home-field-activity").find(`.list-activity .item-activity`).removeClass("hide");
		$(".section-home-field-activity").css("background-image", `url('${bg_img}')`);
	});
}

// tab brand
jQuery(function () {
	$(".list-tab ul li:first-child").addClass("active");
	$(".tab-content").hide();
	$(".tab-content:first").show();
	$(".list-tab ul li").click(function () {
		$(".list-tab ul li").removeClass("active");
		$(this).addClass("active");
		var activeTab = $(this).find("a").attr("href");
		$(".tab-content").hide();
		$(activeTab).show();
		return false;
	});
});

// tab news
jQuery(function () {
	$(".list-tab ul li:first-child").addClass("active");
	$(".wrapper-news").hide();
	$(".wrapper-news:first").show();
	$(".list-tab ul li").click(function () {
		$(".list-tab ul li").removeClass("active");
		$(this).addClass("active");
		var activeTab = $(this).find("a").attr("href");
		$(".wrapper-news").hide();
		$(activeTab).fadeIn();
		return false;
	});
});

//btn load more page career
jQuery(document).ready(function () {
	$(".content-table tbody tr").slice(0, 3).removeClass("hidden").show();
	$(".loading-more-career").on("click", function (e) {
		e.preventDefault();
		$(".content-table tbody tr.hidden").slice(0, 2).removeClass("hidden").slideDown();
		if ($(".content-table tbody tr.hidden").length == 0) {
			$(".loading-more-career").fadeOut();
		}
	});
});

// tab map
const tabLinks = document.querySelectorAll(".box-item");
const tabContents = document.querySelectorAll(".block-map");
tabLinks.forEach((link) => {
	link.addEventListener("click", () => {
		tabLinks.forEach((link) => link.classList.remove("active"));
		link.classList.add("active");

		tabContents.forEach((content) => content.classList.remove("active"));

		const targetTab = document.getElementById(link.dataset.tab);
		targetTab.classList.add("active");
	});
});

// tab poup
function PopupBooking() {
	const btnBooking = document.querySelector(".btn-booking");
	const bookingModel = document.querySelector(".popup-modal");
	const overplay = document.querySelector("#gva-overlay");
	const btnClose = document.querySelector(".popup-modal .btn-close-pop-up");

	if (btnBooking || bookingModel) {
		btnBooking.onclick = function () {
			overplay.style.display = "block";
			bookingModel.style.display = "block";
		};

		btnClose.onclick = function () {
			overplay.style.display = "none";
			bookingModel.style.display = "none";
		};

		overplay.onclick = function () {
			overplay.style.display = "none";
			bookingModel.style.display = "none";
		};
	}
}

jQuery(".form-group").on("click", function (e) {
	e.stopPropagation();

	jQuery(".form-group").not(this).removeClass("active");

	jQuery(this).toggleClass("active");
});

jQuery(".select-options li a").on("click", function (e) {
	var selectedValue = jQuery(this).text();
	jQuery(this).closest(".form-group").find(".select-title").text(selectedValue);
	jQuery(this).closest(".form-group").removeClass("active");
});

jQuery(document).on("click", function () {
	jQuery(".form-group").removeClass("active");
});

// tab tiện ích
jQuery(document).ready(function ($) {
	const $tabLinks = $(".tab-link");
	const $tabContents = $(".tab-content");
	$tabLinks.on("click", function () {
		const targetTab = $(this).data("tab");
		$tabLinks.removeClass("active");
		$tabContents.removeClass("active");
		$(this).addClass("active");
		$("#" + targetTab).addClass("active");
	});
});

// function tabSection 5
jQuery(document).ready(function () {
	$(".gallery-main").removeClass("tab-active");
	$('.gallery-main[data-id="tab1"]').addClass("tab-active");
	$('.tab-feature ul li a[data-id="tab1"]').addClass("active");
	$(".tab-feature ul li a").click(function (e) {
		e.preventDefault();
		$(".gallery-main").removeClass("tab-active");
		$(".tab-feature ul li a").removeClass("active");
		$(this).addClass("active");
		const tabId = $(this).attr("data-id");
		$('.gallery-main[data-id="' + tabId + '"]').addClass("tab-active");
	});
});

// tab section 6
jQuery(document).ready(function () {
	$('.library-wrapper[data-tab="tab-1"]').addClass("active");
	$('.list-title-lb ul li a[data-tab="tab-1"]').addClass("active");

	$(".list-title-lb ul li a").click(function (e) {
		e.preventDefault();
		const tabId = $(this).attr("data-tab");

		$(".library-wrapper").removeClass("active");
		$(".list-title-lb ul li a").removeClass("active");

		$(this).addClass("active");
		$('.library-wrapper[data-tab="' + tabId + '"]').addClass("active");
	});
});

/*==================== Aos Init ====================*/
AOS.init({
	offset: 100,
});
/*==================== Lazyload JS ====================*/
const observer = lozad(".lozad", {
	rootMargin: "10px 0px", // syntax similar to that of CSS Margin
	threshold: 0.5, // ratio of element convergence
	enableAutoReload: true, // it will reload the new image when validating attributes changes
});
observer.observe();
window.observer = observer;
