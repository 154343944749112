export const ajax = {
	filter: function () {
		$(".form-group .select-options a").on("click", function (e) {
			e.preventDefault();
			const $this = $(this);
			const value = $this.attr("href");
			$this.closest(".form-group").find("input").val(value);
			$this.closest(".form-group").find(".select-title").text($this.text());
		});
		$(".section-project-2 form").on("submit", function (e) {
			e.preventDefault();
			const $form = $(this);
			const $response = $(".ajax-response");
			const $loading = $('<div class="loading-wrapper"><div class="loading"></div></div>');

			// Get form data as URL params
			// Get form data and filter out empty values
			const formData = new FormData($form[0]);
			const params = new URLSearchParams();

			for (const [key, value] of formData.entries()) {
				if (value) {
					params.append(key, value);
				}
			}

			const formDataString = params.toString();
			const currentUrl = $form.attr("action");
			const newUrl = formDataString ? `${currentUrl}?${formDataString}` : currentUrl;

			// Show loading
			$response.append($loading);

			$.ajax({
				url: newUrl,
				method: "GET",
				beforeSend: function () {
					$response.append($loading);
				},
				success: function (response) {
					const parser = new DOMParser();
					const doc = parser.parseFromString(response, "text/html");
					const newContent = $(doc).find(".ajax-response").html();

					// Update URL without reload
					// window.history.pushState({}, "", newUrl);
					$response.html(newContent);
				},
				error: function (xhr, status, error) {
					$response.html('<div class="error">Error loading content</div>');
					console.error("Ajax error:", error);
				},
				complete: function () {
					window.observer.observe();
					$loading.remove();
				},
			});
		});
	},
	init: function () {
		this.filter();
	},
};
