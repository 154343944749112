import Swiper from "swiper";
import { Pagination, EffectCreative, Keyboard, Mousewheel, Navigation, Thumbs, EffectFade, Autoplay, EffectCoverflow, Grid, FreeMode } from "swiper/modules";
import { countUpInit } from "./helper";

/**
 * @param swiperInit
 */
export function swiperInit() {
	function handleVideo(swiper, video) {
		if (video) {
			// Reset and play video
			video.currentTime = 0;
			video.play();
			swiper.autoplay.stop();

			const handleTimeUpdate = () => {
				// Check if video is near the end (e.g., last 0.1 seconds)
				if (video.currentTime >= video.duration - 0.1) {
					video.removeEventListener("timeupdate", handleTimeUpdate);
					swiper.autoplay.start();
					swiper.slideNext();
				}
			};

			video.addEventListener("timeupdate", handleTimeUpdate);
		}
	}

	function pauseCurrentVideo(swiper) {
		const currentSlide = swiper.slides[swiper.activeIndex];
		const currentVideo = currentSlide.querySelector("video");
		if (currentVideo) {
			currentVideo.pause();
			currentVideo.currentTime = 0;
		}
	}
	new Swiper(".section-home-banner .swiper", {
		slidesPerView: 1,
		spaceBetween: 0,
		speed: 1000,
		loop: true,
		autoplay: {
			delay: 5000,
		},
		effect: "creative",
		creativeEffect: {
			prev: {
				shadow: true,
				translate: ["-80%", 0, -1],
			},
			next: {
				translate: ["100%", 0, 0],
			},
		},
		modules: [Pagination, Navigation, Autoplay, EffectCreative],
		pagination: {
			el: ".section-home-banner .swiper-pagination",
			clickable: true,
		},
		navigation: {
			nextEl: ".section-home-banner .btn-next",
			prevEl: ".section-home-banner .btn-prev",
		},
		on: {
			init: function () {
				const initialSlide = this.slides[this.activeIndex];
				const initialVideo = initialSlide.querySelector("video");
				handleVideo(this, initialVideo);
			},
			beforeTransitionStart: function () {
				pauseCurrentVideo(this);
			},
			slideChangeTransitionEnd: function () {
				console.log("slideChangeTransitionEnd");
				const currentSlide = this.slides[this.activeIndex];
				const video = currentSlide.querySelector("video");
				handleVideo(this, video);
			},
		},
	});
	// Project Detail - Slide

	$(".section-detail-pro-6 .wrapper-slide-thumbs .swiper").each(function (index) {
		const nameVariable = `swiper_detail_thumbs_${index}`;
		window[nameVariable] = new Swiper(this, {
			slidesPerView: 2.2,
			spaceBetween: 15,
			freeMode: true,
			breakpoints: {
				768: {
					slidesPerView: 3.2,
					spaceBetween: 15,
				},
				1024: {
					slidesPerView: 1.5,
					spaceBetween: 15,
				},
				1200: {
					slidesPerView: 2.2,
					spaceBetween: 20,
				},
			},
		});
	});
	$(".section-detail-pro-6 .wrapper-gallery .swiper").each(function (index) {
		const nameVariable = `swiper_detail_gallery_${index}`;
		window[nameVariable] = new Swiper(this, {
			slidesPerView: 1,
			modules: [Thumbs, Navigation],
			navigation: {
				nextEl: $(this).closest(".section-detail-pro-6").find(".btn-next")[0],
				prevEl: $(this).closest(".section-detail-pro-6").find(".btn-prev")[0],
			},
			thumbs: {
				swiper: window[`swiper_detail_thumbs_${index}`],
			},
		});
	});
	//
	$(".home-cols-1 .swiper").each(function () {
		new Swiper(this, {
			modules: [Autoplay, Pagination, Navigation],
			slidesPerView: "auto",
			spaceBetween: 20,
			// autoplay: {
			// 	delay: 5000,
			// },
			speed: 600,
			// autoHeight: true,
			loop: true,
			pagination: {
				el: ".home-cols-1 .swiper-pagination",
				clickable: true,
			},
			navigation: {
				nextEl: $(this).closest(".home-cols-1").find(".button-next")[0],
				prevEl: $(this).closest(".home-cols-1").find(".button-prev")[0],
			},
		});
	});
	//
	new Swiper(".home-cols-3-project  .swiper", {
		modules: [Autoplay],
		spaceBetween: 30,
		autoplay: {
			delay: 3500,
		},
		loop: true,
		breakpoints: {
			768: {
				slidesPerView: 1,
			},
			1200: {
				slidesPerView: 3,
			},
		},
	});

	$(".home-cols-3 .swiper").each(function () {
		new Swiper(this, {
			// slidesPerView: 3,
			spaceBetween: 40,
			modules: [Navigation, Autoplay],
			autoplay: {
				delay: 3500,
			},
			loop: true,
			navigation: {
				nextEl: ".home-cols-3 .button-next",
				prevEl: ".home-cols-3 .button-prev",
			},
			breakpoints: {
				768: {
					slidesPerView: 1,
				},
				1200: {
					slidesPerView: 3,
				},
			},
		});
	});
	$(".swiper-cols-1 .swiper").each(function () {
		new Swiper(this, {
			slidesPerView: 1,
			spaceBetween: 20,
			modules: [Navigation],
			navigation: {
				nextEl: $(this).closest(".swiper-cols-1").find(".btn-next")[0],
				prevEl: $(this).closest(".swiper-cols-1").find(".btn-prev")[0],
			},
		});
	});
	$(".swiper-cols-2 .swiper").each(function () {
		new Swiper(this, {
			slidesPerView: 1,
			spaceBetween: 20,
			modules: [Navigation],
			navigation: {
				nextEl: $(this).closest(".swiper-cols-1").find(".btn-next")[0],
				prevEl: $(this).closest(".swiper-cols-1").find(".btn-prev")[0],
			},
			breakpoints: {
				768: {
					slidesPerView: 2,
					spaceBetween: 40,
				},
			},
		});
	});
	$(".swiper-cols-2 .swiper").each(function () {
		new Swiper(this, {
			slidesPerView: 1,
			spaceBetween: 20,
			modules: [Navigation],
			navigation: {
				nextEl: $(this).closest(".swiper-cols-1").find(".btn-next")[0],
				prevEl: $(this).closest(".swiper-cols-1").find(".btn-prev")[0],
			},
			breakpoints: {
				768: {
					slidesPerView: 2,
					spaceBetween: 40,
				},
			},
		});
	});
	$(".swiper-cols-3 .swiper").each(function () {
		const config = {
			slidesPerView_sm: this.getAttribute("swiper-sm-slides") || 1,
			slidesPerView_md: this.getAttribute("swiper-md-slides") || 2,
			slidesPerView_lg: this.getAttribute("swiper-lg-slides") || 3,
			slidesPerView_xl: this.getAttribute("swiper-xl-slides") || 3,
			spaceBetween_sm: this.getAttribute("swiper-sm-padding") || 15,
			spaceBetween_md: this.getAttribute("swiper-md-padding") || 15,
			spaceBetween_lg: this.getAttribute("swiper-lg-padding") || 40,
			spaceBetween_xl: this.getAttribute("swiper-xl-padding") || 40,
		};
		new Swiper(this, {
			slidesPerView: config.slidesPerView_sm,
			spaceBetween: config.spaceBetween_sm,
			modules: [Navigation, Pagination],
			speed: 1000,
			navigation: {
				nextEl: $(this).closest(".swiper-cols-3").find(".btn-next")[0],
				prevEl: $(this).closest(".swiper-cols-3").find(".btn-prev")[0],
			},
			pagination: {
				el: $(this).closest(".swiper-cols-3").find(".swiper-pagination")[0],
				clickable: true,
			},
			breakpoints: {
				768: {
					slidesPerView: config.slidesPerView_md,
					spaceBetween: config.spaceBetween_md,
				},
				1024: {
					slidesPerView: config.slidesPerView_lg,
					spaceBetween: config.spaceBetween_lg,
				},
				1200: {
					slidesPerView: config.slidesPerView_xl,
					spaceBetween: config.spaceBetween_xl,
				},
			},
		});
	});
	$(".swiper-cols-4 .swiper").each(function () {
		new Swiper(this, {
			slidesPerView: 1,
			spaceBetween: 20,
			modules: [Navigation],
			navigation: {
				nextEl: $(this).closest(".swiper-cols-4").find(".btn-next")[0],
				prevEl: $(this).closest(".swiper-cols-4").find(".btn-prev")[0],
			},
			breakpoints: {
				768: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 4,
					spaceBetween: 40,
				},
			},
		});
	});

	$(".home-cols-9 .swiper").each(function (index) {
		const nameVariable = `swiper_brand_${index}`;
		window[nameVariable] = new Swiper(this, {
			modules: [Autoplay],
			slidesPerView: 3,
			spaceBetween: 32,
			speed: 2000,
			loop: true,
			observer: true,
			observeParents: true,
			modules: [Autoplay],
			allowTouchMove: false,
			autoplay: {
				delay: 0,
				pauseOnMouseEnter: false, // added
				disableOnInteraction: false,
			},
			on: {
				init: function () {
					$(".section-about-7 li a").on("click", function () {
						// Get last character of href
						const index = $(this).attr("href").slice(-1);
						const nameVariable = `swiper_brand_${index - 1}`;
						setTimeout(() => {
							window[nameVariable]?.autoplay?.stop();
							window[nameVariable].slideReset();
							// Reset index of swiper
							window[nameVariable].slideTo(window[nameVariable].activeIndex + 1);
							window[nameVariable]?.autoplay?.start();
							window[nameVariable].update();
						}, 600);
					});
					if (this.slides.length < this.params.slidesPerView) {
						console.log(this.slides.length, this.params.slidesPerView);
						// Disable swiper init
						$(this.el).closest(".wrapper-brand").addClass("slide-center");
						this.destroy(true, true);
					}
				},
			},
			breakpoints: {
				768: {
					slidesPerView: 5,
				},
				1200: {
					slidesPerView: 9,
				},
			},
		});
	});

	// swiper tiện ích
	$(".home-cols-feature .swiper").each(function () {
		new Swiper(this, {
			modules: [Autoplay, Navigation],
			centeredSlides: true,
			loop: true,
			slidesPerView: 1,
			loopAdditionalSlides: 3,
			spaceBetween: 40,
			autoplay: {
				delay: 3500,
			},
			observer: true,
			observeParents: true,
			navigation: {
				nextEl: $(this).closest(".home-cols-feature").find(".button-next")[0],
				prevEl: $(this).closest(".home-cols-feature").find(".button-prev")[0],
			},
		});
	});

	// slider year
	$(".home-cols-5 .swiper").each(function () {
		const mySwiper = new Swiper(this, {
			modules: [Pagination, Navigation],
			autoHeight: true,
			slideToClickedSlide: true,
			centeredSlides: false,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
			},
			speed: 500,
			direction: "horizontal",
			pagination: {
				el: ".swiper-pagination",
				type: "progressbar",
			},
			loop: true,
			effect: "slide",
			spaceBetween: 40,
			breakpoints: {
				768: {
					slidesPerView: 2,
				},
				1200: {
					slidesPerView: 4,
				},
			},
			on: {
				init: function () {
					$(".pagination-year .year-pagination-switch").removeClass("active");
					$(".pagination-year .year-pagination-switch").eq(0).addClass("active");
				},
				slideChangeTransitionStart: function () {
					$(".pagination-year .year-pagination-switch").removeClass("active");
					$(".pagination-year .year-pagination-switch").eq(this.realIndex).addClass("active");
				},
			},
		});

		// Xử lý click vào pagination
		// $(".pagination-year .year-pagination-switch").click(function () {
		// 	const index = $(this).index(); // Lấy index của pagination
		// 	mySwiper.slideTo(index); // Chuyển slide dựa trên index
		// 	$(".pagination-year .year-pagination-switch").removeClass("active");
		// 	$(this).addClass("active");
		// });

		// Xử lý click vào pagination
		$(".pagination-year .year-pagination-switch").on("click", function () {
			const index = $(this).index(); // Lấy index của pagination được click
			// Chuyển đến slide tương ứng
			mySwiper.slideToLoop(index);
			// Cập nhật trạng thái centeredSlides
			if (!mySwiper.params.centeredSlides) {
				mySwiper.params.centeredSlides = true;
				mySwiper.update();
			}
			$(".pagination-year .year-pagination-switch").removeClass("active");
			$(this).addClass("active");
		});
	});

	// gallery sản phẩm
	// var thumbs = new Swiper(".gallery-thumbs", {
	// 	slidesPerView: 8,
	// 	spaceBetween: 20,
	// 	speed: 500,
	// 	loop: true,
	// 	// slideToClickedSlide: true,
	// });
	// var slider = new Swiper(".thumb-main", {
	// 	modules: [Navigation, Thumbs, Mousewheel],
	// 	slidesPerView: 1,
	// 	speed: 500,
	// 	loop: true,
	// 	mousewheel: {
	// 		forceToAxis: true,
	// 		// thresholdDelta: 20,
	// 		sensitivity: 1.3,
	// 	},
	// 	thumbs: {
	// 		swiper: thumbs,
	// 	},
	// 	navigation: {
	// 		nextEl: $(this).closest(".thumb-main").find(".btn-next")[0],
	// 		prevEl: $(this).closest(".thumb-main").find(".btn-prev")[0],
	// 	},
	// });

	// function setupSlideButtons(initSwiper, element, prevBtn, nextBtn) {
	// 	const prevButton = document.querySelector(`${element} ${prevBtn}`);
	// 	const nextButton = document.querySelector(`${element} ${nextBtn}`);

	// 	if (prevButton) {
	// 		prevButton.addEventListener("click", function () {
	// 			initSwiper.slidePrev();
	// 			initSwiper.thumbs.swiper.slidePrev();
	// 		});
	// 	}
	// 	if (nextButton) {
	// 		nextButton.addEventListener("click", function () {
	// 			initSwiper.slideNext();
	// 			initSwiper.thumbs.swiper.slideNext();
	// 		});
	// 	}
	// }
	// setupSlideButtons(slider, ".btn-swiper-style-1", ".button-prev", ".button-next");

	// Slide Project Detail
	$(".section-detail-pro-5 .tabslet-content").each(function (tabIndex) {
		const $currentTab = $(this);
		const $thumbContainer = $currentTab.find(".slide-thumb .swiper")[0];
		const $mainContainer = $currentTab.find(".slide-main .swiper")[0];

		// Only initialize if both containers exist
		if ($thumbContainer && $mainContainer) {
			// Initialize thumb swiper
			const thumbSwiper = new Swiper($thumbContainer, {
				slidesPerView: 3,
				spaceBetween: 10,
				modules: [Navigation, Grid],
				observer: true,
				observeParents: true,
				freeMode: true,
				watchSlidesProgress: true,
				breakpoints: {
					768: {
						slidesPerView: 2,
						spaceBetween: 5,
						grid: {
							rows: 4,
							fill: "column",
						},
					},
					1200: {
						slidesPerView: 2,
						spaceBetween: 15,
						grid: {
							rows: 4,
							fill: "column",
						},
					},
				},
			});

			// Initialize main swiper
			new Swiper($mainContainer, {
				spaceBetween: 10,
				speed: 500,
				slidesPerView: 1,
				observer: true,
				observeParents: true,
				modules: [Navigation, Thumbs],
				navigation: {
					nextEl: $currentTab.find(".btn-next")[0],
					prevEl: $currentTab.find(".btn-prev")[0],
				},
				thumbs: {
					swiper: thumbSwiper,
				},
			});
		}
	});
}

// Reuseable Swiper

const swiperActivityField = new Swiper(".section-home-field-activity .swiper", {
	slidesPerView: 1.5,
	spaceBetween: 20,
	modules: [Navigation, Pagination],
	navigation: {
		nextEl: ".section-home-field-activity .btn-next",
		prevEl: ".section-home-field-activity .btn-prev",
	},
	pagination: {
		el: ".section-home-field-activity .swiper-pagination",
		type: "progressbar",
	},
	breakpoints: {
		768: {
			slidesPerView: 3,
			spaceBetween: 15,
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 15,
		},
		1200: {
			slidesPerView: 3,
			spaceBetween: 30,
		},
	},
});
window.swiperActivityField = swiperActivityField;
new Swiper(".section-home-project .swiper", {
	slidesPerView: "auto",
	spaceBetween: 4,
	modules: [Navigation, Pagination],
	navigation: {
		nextEl: ".section-home-project .btn-next",
		prevEl: ".section-home-project .btn-prev",
	},
});
var timerShowPagination;
export const Fullpage = {
	generateThumbs: function () {
		const countSlide = jQuery(".fullpage-swiper > .swiper > .swiper-wrapper > .swiper-slide").length;
		jQuery(".fullpage-swiper > .swiper > .swiper-wrapper > .swiper-slide").each(function (index) {
			let title = $(this).data("title");
			jQuery(".fullpage-slide-thumb .swiper-wrapper").append(`<div class="swiper-slide"><div class="item-thumb"><p>${title || ""}</p><span>0${index + 1}</span></div></div>`);
		});
	},
	swiper: function () {
		var fullpage_thumb = new Swiper(".fullpage-slide-thumb .swiper", {
			direction: "vertical",
			speed: 800,
			allowTouchMove: false,
			observer: true,
			spaceBetween: 12,
			observeParents: true,
		});
		var fullpage_main = new Swiper(".fullpage-swiper .swiper-full-current", {
			spaceBetween: 0,
			direction: "vertical",
			speed: 1000,
			observer: true,
			observeParents: true,
			modules: [Pagination, EffectCreative, Keyboard, Mousewheel, Navigation, Thumbs],
			mousewheel: {
				forceToAxis: true,
				thresholdTime: 1200,
			},
			thumbs: {
				swiper: fullpage_thumb,
			},
			keyboard: {
				enabled: true,
			},
			freeMode: false,
			slidesPerView: 1,
			allowTouchMove: false,
			effect: "creative",
			creativeEffect: {
				prev: {
					shadow: true,
					translate: [0, "-100%", -1],
				},
				next: {
					translate: [0, "100%", 0],
				},
			},
			on: {
				transitionEnd: async () => {
					const color = $(".swiper-full-current > .swiper-wrapper > .swiper-slide-active .section-swiper").data("color");
					if (color == "white") {
						$(".fullpage-slide-thumb").addClass("color-white");
					} else {
						$(".fullpage-slide-thumb").removeClass("color-primary");
					}
					//
					const isIndicator = $(".swiper-full-current > .swiper-wrapper > .swiper-slide-active .section-swiper").find(".indicator-swipe").length > 0;
					if (isIndicator) {
						$(".indicator-swipe").addClass("active");
						setTimeout(() => {
							$(".indicator-swipe").removeClass("active");
						}, 3000);
					}
					window.itemVarHeight();
				},
				beforeTransitionStart: async (swiper) => {
					const isCountUp = $(".swiper-full-current > .swiper-wrapper > .swiper-slide-active .section-swiper").find(".countup").length > 0;
					if (isCountUp) {
						const countUp = countUpInit();
						countUp.reset();
						countUp.start();
					}
				},
				slideChange: async () => {
					if (fullpage_main.activeIndex > fullpage_main.previousIndex) {
						jQuery("body").addClass("hidden-fullpage");
					} else {
						jQuery("body").removeClass("hidden-fullpage");
					}
				},
				init: async () => {},
				afterInit: async (swiper) => {
					const number = window.location.hash.replace("#", "");
					if (number) {
						const lastNumber = number.split("-")[1];
						swiper.slideTo(lastNumber - 1);
					}
				},
			},
		});
		// How to catch url hash change when click button, all element have attribute data-hash
		$(".section-home-banner a").on("click", function () {
			const hash = this.hash.substr(1);
			if (hash) {
				const number = hash.replace("#", "");
				const lastNumber = number.split("-")[1];
				fullpage_main.slideTo(lastNumber - 1);
				return false;
			} else {
				return true;
			}
		});

		$(".button-to-top").on("click", function () {
			fullpage_main.slideTo(0);
		});
	},
	init: function () {
		if (window.matchMedia("(min-width: 1200px)").matches) {
			Fullpage.generateThumbs();
			Fullpage.swiper();
			$(".header-toggle-fullpage").on("click", function () {
				jQuery("body").removeClass("hidden-fullpage");
			});
		}
	},
};
